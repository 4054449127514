import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Form from "../components/Form";


function Signup({ pageContext }) {
  console.warn("Signup pageContext", pageContext);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
      <Form />
    </GoogleReCaptchaProvider>
  );
}

export default Signup;
